import React, { useEffect, useState } from "react";
import { builder, Builder, BuilderComponent } from "@builder.io/react";
import { Helmet } from "react-helmet";
import { Provider } from "../context/Context";
import getOrderData from "../functions/orderData";
import { getSalesTaxes } from "../functions/salesTaxes";
import submitOrders from "../functions/submitOrders";
import Loading from "../components/Loading/Loading";
import StagingIndicator from "../components/StagingIndicator/StagingIndicator";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import axios from "axios";

import "../../static/css/upsell.css";
import getParamFromQuery from "../utils/getParamFromQuery";

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = "PetLab Co.";
const defaultDescription = "Supplies, Treats, Snacks & Chews - Join The Pack";

const UpsellTemplate = props => {
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [userToken, setUserToken] = useState("token");
  const [path, setPath] = useState(null);
  const [builderContentJson, setBuilderContentJson] = useState(null);
  const [selectorData, setSelectorData] = useState(null);

  const fetchCheckoutData = async productSelectorId => {
    return await axios
      .post(`/.netlify/functions/upsell-data`, { id: productSelectorId })
      .then(res => {
        setLoadingPreview(false);
        return res.data;
      });
  };

  useEffect(() => {
    const path = getParamFromQuery("url");
    const token = getParamFromQuery("token");
    setPath(path);
    setUserToken(token);
    builder
      .get("upsell", { url: path })
      .promise()
      .then(res => {
        fetchCheckoutData(res.data.selector.id).then(res => {
          console.log(res);
          setSelectorData(res);
        });
        setBuilderContentJson(res);
      });
  }, []);

  const {
    countdownDuration = 900,
    title: pageTitle,
    description: pageDescription,
    settings = {}
  } = builderContentJson?.data || {};

  const {
    showLoading = false,
    skipOffers = false,
    offerByVariantId
  } = settings;

  const endTime = new Date();
  endTime.setSeconds(endTime.getSeconds() + countdownDuration);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [upsellCart, setUpsellCart] = useState([]);
  const [preFetch, setPreFetch] = useState(false);
  const [salesTaxes, setSalesTaxes] = useState({
    label: "Sales tax",
    tax: []
  });
  const [orderData, setOrderData] = useState({});
  const [displayLoading, setDisplayLoading] = useState(
    showLoading && !Builder.isEditing
  );

  const context = {
    selectorData: selectorData,
    currentIndex: currentIndex,
    setCurrentIndex: setCurrentIndex,
    buttonClicked: buttonClicked,
    setButtonClicked: setButtonClicked,
    userToken: userToken,
    upsellCart: upsellCart,
    setUpsellCart: setUpsellCart,
    salesTaxes: salesTaxes,
    orderData: orderData,
    countDownEnd: endTime
  };

  useEffect(() => {
    if (skipOffers && !Builder.isEditing) {
      submitOrders(userToken, []);
      return;
    }

    if (!preFetch) {
      // get order data
      getOrderData(userToken).then(data => {
        setOrderData(data);

        let found = false;
        if (offerByVariantId) {
          offerByVariantId.every(({ variantId, offerIndex }) => {
            const item = data?.items.find(
              ({ variant_id }) => variant_id === variantId
            );
            if (item) {
              found = true;
              setCurrentIndex(offerIndex);
              if (typeof selectorData?.offers[offerIndex] === "undefined") {
                submitOrders(userToken, upsellCart);
              }
              return false;
            }
            return true;
          });
        }
        if (!offerByVariantId || !found) {
          setDisplayLoading(false);
        }
      });

      // get sales taxes
      getSalesTaxes(selectorData?.store, selectorData?.offers, userToken).then(
        data => {
          setSalesTaxes(data);
        }
      );

      setPreFetch(true);
    }
  }, [
    currentIndex,
    selectorData,
    preFetch,
    setPreFetch,
    userToken,
    setSalesTaxes,
    setOrderData,
    offerByVariantId,
    setCurrentIndex,
    upsellCart,
    skipOffers
  ]);

  if (loadingPreview) {
    return <>please wait, preview is still fetching the data...</>;
  }

  return (
    <Provider value={context}>
      <ReactNotifications />
      <Helmet>
        <title>{pageTitle || defaultTitle}</title>
        <meta
          name="description"
          content={pageDescription || defaultDescription}
        />
        <meta name="funnel-id" content={builderContentJson.id} />
        <meta
          name="product-selector-id"
          content={builderContentJson.data.selector.id}
        />
      </Helmet>
      {/** name of the model is landing page, change it if you decided to build*/}
      <BuilderComponent name="upsell" content={builderContentJson} />
      {displayLoading && <Loading />}
      {process.env.GATSBY_UPSELL_STAGING && <StagingIndicator />}
    </Provider>
  );
};

export default UpsellTemplate;
